import React, { createContext, useContext } from "react";
import { Script } from "gatsby";

import config from "../../gatsby-config";
const recaptchaSiteKey =
  (config.siteMetadata?.recaptchaSiteKey as string) || "";

type ScriptsContextType = {};

const ScriptsContext = createContext<ScriptsContextType | undefined>(undefined);
export const useScripts = () =>
  useContext(ScriptsContext) as ScriptsContextType;

const ScriptsProvider = ({ children }: { children: any }) => {
  const value = {};

  return (
    <ScriptsContext.Provider value={value}>
      {children}
      <Script
        src={`https://www.google.com/recaptcha/api.js?render=${recaptchaSiteKey}`}
      />
    </ScriptsContext.Provider>
  );
};

export default ScriptsProvider;
