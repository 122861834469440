import React from "react";

import "@fontsource/lato";
import "@fontsource/lato/300.css";
import "@fontsource/lato/700.css";
import "@fontsource/lato/900.css";
import "@fontsource/libre-franklin";
import "@fontsource/libre-baskerville";
import "./src/styles/global.css";

import wrapper from "./wrapper";
export const wrapRootElement = wrapper;

export const wrapPageElement = ({
  element,
  props,
}: {
  element: any;
  props: any;
}) => {
  const getLayout = element.type.getLayout || ((page: any, props: any) => page);
  return getLayout(element, props);
};
