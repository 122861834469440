import React from "react";

import ScriptsProvider from "./src/providers/scripts";

interface ComposeProps {
  components: [
    React.JSXElementConstructor<React.PropsWithChildren<any>>,
    any
  ][];
  children: React.ReactNode;
}

const Compose = (props: ComposeProps) => {
  const { components = [], children } = props;

  return (
    <>
      {components.reduceRight((acc, [Comp, attrs]) => {
        return <Comp {...attrs}>{acc}</Comp>;
      }, children)}
    </>
  );
};

const components: [
  React.JSXElementConstructor<React.PropsWithChildren<any>>,
  any
][] = [[ScriptsProvider, {}]];

export default ({ element }: { element: any }) => {
  return <Compose components={components}>{element}</Compose>;
};
