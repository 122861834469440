module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://cms.buildem.us/graphql","develop":{"hardCacheMediaFiles":false,"nodeUpdateInterval":5000,"hardCacheData":false},"type":{"__all":{"limit":null},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"html":{"imageQuality":70,"placeholderType":"dominantColor","useGatsbyImage":true,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"createStaticFiles":true,"generateWebpImages":true,"generateAvifImages":false},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.buildem.us"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Buildem","short_name":"Buildem","start_url":"/","background_color":"#ffffff","theme_color":"#ff8248","theme_color_in_head":false,"display":"browser","icon":"src/images/buildem-initial-logo.png","legacy":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a519ba32c79f0508daaab579db40e51c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-aaaaaaaaaa","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
