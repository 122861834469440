import type { GatsbyConfig } from "gatsby";

const config: GatsbyConfig = {
  siteMetadata: {
    author: `BuildEm USA Corp`,
    //image: `https://www.techslice-ventures.com/images/techslice-team-feature-1.jpg`,
    siteUrl: `https://www.buildem.us`,
    recaptchaSiteKey: `6LfGsd8mAAAAAEWvdUARfPSzLCRhyvtk93qmlcEs`,
  },
  graphqlTypegen: true,
  plugins: [
    {
      resolve: `gatsby-source-wordpress`,
      options: {
        url: `https://cms.buildem.us/graphql`,
        develop: {
          hardCacheMediaFiles: false,
        },
        type: {
          __all: {
            limit: process.env.NODE_ENV === `development` ? 1000 : null,
          },
        },
        html: {
          imageQuality: 70,
          placeholderType: `dominantColor`,
        },
      },
    },
    `gatsby-plugin-postcss`,
    {
      resolve: `gatsby-plugin-canonical-urls`,
      options: {
        siteUrl: `https://www.buildem.us`,
      },
    },
    {
      resolve: `gatsby-plugin-sitemap`,
      options: {
        query: `
        {
          allSitePage {
            nodes {
              path
            }
          }
          allWpContentNode(filter: {nodeType: {in: ["Page", "Post"]}}) {
            nodes {
              uri
              modifiedGmt
            }
          }
        }
      `,
        resolveSiteUrl: () => `https://www.buildem.us`,
        resolvePages: ({
          allSitePage: { nodes: allPages },
          allWpContentNode: { nodes: allWpNodes },
        }: any) => {
          const wpNodeMap = allWpNodes.reduce((acc: any, node: any) => {
            const { uri } = node;
            acc[uri] = node;

            return acc;
          }, {});

          return allPages.map((page: any) => {
            return { ...page, ...wpNodeMap[page.path] };
          });
        },
        serialize: ({ path, modifiedGmt }: any) => {
          return {
            url: path,
            lastmod: modifiedGmt,
          };
        },
      },
    },
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `Buildem`,
        short_name: `Buildem`,
        start_url: `/`,
        background_color: `#ffffff`,
        theme_color: `#ff8248`,
        theme_color_in_head: false,
        display: `browser`,
        icon: `src/images/buildem-initial-logo.png`, //add updated logo when ready
      },
    },
    {
      resolve: `gatsby-plugin-sharp`,
      options: {
        defaults: {
          placeholder: `dominantColor`,
          quality: 70,
        },
      },
    },
    `gatsby-transformer-sharp`,
    `gatsby-plugin-image`,
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `./src/images/`,
      },
      __key: `images`,
    },
    {
      resolve: `gatsby-plugin-google-analytics`,
      options: {
        trackingId: `G-aaaaaaaaaa`,
      },
    },
  ],
};

export default config;
